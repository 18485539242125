@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,body{max-width:100%;max-height:100%;overflow-x:hidden;height:100%;width:100%;background-color:#333}body{margin:0;padding:0}h1{color:white;font-family:'Montserrat', sans-serif;font-size:60px;-webkit-margin-before:0px;margin-block-start:0px;-webkit-margin-after:0px;margin-block-end:0px;padding:15px}button{border-radius:5px}a{text-decoration:none}.text{font-family:'Montserrat', sans-serif;color:white}.top-bar{background-color:#21d4da;width:100%;text-align:center;box-shadow:1px 1px 25px 10px rgba(255,255,255,0.1);bottom:1px;z-index:-1}.main-content{width:100%;height:100%;display:block}.main-content .button-wrap{display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center;-webkit-justify-content:center;justify-content:center}.main-content .button-wrap .button-views{display:block;width:60%}.main-content .button-wrap .button-views .link-image-wrap{float:left;height:100%;margin:5px}.main-content .button-wrap .button-views .link-image-wrap .link-image{height:30px;width:auto;float:left;vertical-align:middle}.main-content .button-wrap .button-views .link-text{font-size:20px;float:left;font-family:'Montserrat', sans-serif;color:white}.main-content .button-wrap .button-views .center{text-align:center;width:100%}.footer{position:fixed;bottom:0px;width:100%;padding-left:1%;padding-right:1%;padding-bottom:10px;background:#21d4da;color:white;font-family:'Montserrat', sans-serif}.footer .cright{width:100%;text-align:center;font-size:14px}.footer .cright p a{color:white;text-decoration:none}@media screen and (max-width: 420px){.page .top-bar h1{font-size:48px}.page .main-content .button-wrap .button-views{width:65%}.page .main-content .button-wrap .button-views .link-text{font-size:16px}.page .footer .cright{font-size:10px}}@media screen and (max-width: 310px){.page .main-content .button-wrap .button-views .link-text{font-size:14.5px}}

.link-image-wrap{float:left;height:100%;margin:5px}.link-image-wrap .link-image{height:30px;width:auto;float:left;vertical-align:middle}.link-text{font-size:20px;float:left;font-family:'Montserrat', sans-serif;color:white}.center{text-align:center;width:100%}

