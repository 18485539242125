@import "../../variables.scss";

.link-image-wrap {
  float: left;
  height: 100%;
  margin: 5px;

  .link-image {
    height: 30px;
    width: auto;
    float: left;
    vertical-align: middle;
  }
}

.link-text {
  font-size: 20px;
  float: left;
  font-family: 'Montserrat', sans-serif;
  color: white;
}

.center {
  text-align: center;
  width: 100%;
}
