@import "../variables.scss";

html, body {
  max-width: 100%;
  max-height: 100%;
  overflow-x: hidden;

  height: 100%;
  width: 100%;

  background-color: rgb(51, 51, 51);;
}

body {
  margin: 0;
  padding: 0;
}

h1 {
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-size: 60px;
  margin-block-start: 0px;
  margin-block-end: 0px;
  padding: 15px;
}

button {
  border-radius: 5px;
}

a {
  text-decoration: none;
}

//classes

.text {
  font-family: 'Montserrat', sans-serif;
  color: white;
}

.top-bar {
  background-color: $theme-colour;
  width: 100%;

  text-align: center;
  box-shadow: 1px 1px 25px 10px rgba(255,255,255,.1);
  bottom:1px;
  z-index:-1;
}

.main-content {
  width: 100%;
  height: 100%;
  display: block;
  //margin: 10px;

  .button-wrap {
    display: flex;
    align-items: center;
    justify-content: center;

    .button-views {
      display: block;
      width: 60%;

        .link-image-wrap {
          float: left;
          height: 100%;
          margin: 5px;

          .link-image {
            height: 30px;
            width: auto;
            float: left;
            vertical-align: middle;
          }
        }

        .link-text {
          font-size: 20px;
          float: left;
          font-family: 'Montserrat', sans-serif;
          color: white;
        }

        .center {
          text-align: center;
          width: 100%;
        }
    }
  }
}

.footer {
  position: fixed;
  bottom: 0px;

  width: 100%;
  padding-left: 1%;
  padding-right: 1%;
  padding-bottom: 10px;

  background: $theme-colour;
  color: white;
  font-family: 'Montserrat', sans-serif;

  .cright {
    width: 100%;
    text-align: center;
    font-size: 14px;

    p {
      a {
        color: white;
        text-decoration: none;
      }
    }
  }
}

@media screen and (max-width: 420px) {
  .page {
    .top-bar {
      h1 {
        font-size: 48px;
      }
    }

    .main-content .button-wrap .button-views {

      width: 65%;

      .link-text {
        font-size: 16px;
      }
    }

    .footer {
      .cright {
        font-size: 10px;
      }
    }
  }
}

@media screen and (max-width: 310px){
  .page {
    .main-content .button-wrap .button-views .link-text {
      font-size: 14.5px;
    }
  }
}